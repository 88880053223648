import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "showPdf",
  style: {"padding":"0"}
}
const _hoisted_2 = ["src"]

import { onMounted, getCurrentInstance, ref } from 'vue';
  import { useRoute } from 'vue-router';
  
export default /*@__PURE__*/_defineComponent({
  __name: 'ShowPdf',
  setup(__props) {

  const { appContext: { config : { globalProperties } } } = getCurrentInstance() as any;
  const route = useRoute()
  let link = ref('')

  onMounted(() => {
    showPage()
  })
  function showPage() {
    globalProperties.$API.StandardPdf({
      standardNumber: route.query.number
    }).then((res: any) => {
      if(res.data.code == 200) {
      //  link.value = res.data.data
      window.location.assign(res.data.data);
      }
    })
  }

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("iframe", {
      id: "iframe",
      src: '/static/pdfjs/web/viewer.html?file=' + _unref(link)
    }, null, 8, _hoisted_2)
  ]))
}
}

})